export default `
{
  children {
    _id
    name
    site {
      site_url
    }
    navigation {
      nav_title
    }
    children {
      _id
      name
      site {
        site_url
      }
      navigation {
        nav_title
      }
    }
  }
}
`;
