import React from "react";
import PropTypes from "prop-types";
import get from "lodash.get";
import { Button, keyframes, styled, theme } from "@washingtonpost/wpds-ui-kit";

const fadeOut = keyframes({
  "0%": { opacity: 1 },
  "100%": { opacity: 0 }
});

const fadeIn = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 }
});
const Text = styled("p", {
  fontFamily: theme.fonts.subhead,
  fontSize: theme.fontSizes["087"]
});

const StyledUl = styled("ul", {
  display: "none",
  "@media (min-width: 1150px)": {
    flex: 14,
    $$navHeight: "60px",
    height: "$$navHeight",
    lineHeight: "$$navHeight",
    pointer: "cursor",
    whiteSpace: "nowrap",
    flexWrap: "wrap",
    alignItems: "center",
    animation: `${fadeIn} 0.7s cubic-bezier(.4,0,.7,.2) 0s`,
    opacity: 1,
    width: "100%",
    display: "flex",
    marginLeft: "8px",
    padding: 0,
    overflow: "hidden"
  },
  variants: {
    showLogo: {
      true: {
        "@media (min-width: 1150px)": {
          opacity: 0,
          animation: `${fadeOut} 0.7s cubic-bezier(0,0,.2,1) 0s`
        }
      }
    }
  }
});

const StyledLi = styled("li", {
  flexBasis: "fit-content",
  height: "60px",
  display: "flex",
  alignItems: "center"
});

const HeaderNavLinks = (props) => {
  const { inlineLinkData, showLogo } = props;

  return (
    <StyledUl data-qa="header-nav-links-ul" showLogo={showLogo}>
      {inlineLinkData &&
        inlineLinkData.map((item, i) => {
          const name = get(item, "name", i);
          let url = get(item, "url", "");
          url = url
            ? `${url}?itid=hp_top_nav_${(name || "").toLowerCase()}`
            : url;

          return (
            <StyledLi key={`${name}-${url}`}>
              <Button as="a" href={url} css={{ border: "none" }}>
                <Text>{name}</Text>
              </Button>
            </StyledLi>
          );
        })}
    </StyledUl>
  );
};

HeaderNavLinks.propTypes = {
  inlineLinkData: PropTypes.array,
  showLogo: PropTypes.bool
};

export default HeaderNavLinks;
