export default `
{
  children {
    _id
    name
    site {
      site_url
      additional_info {
        authenticatedOnly
        subscriberOnly
      }
    }
  }
}
`;
