import React from "react";
import PropTypes from "prop-types";

import { Divider, keyframes, theme, styled } from "@washingtonpost/wpds-ui-kit";

import { WashingtonPost, WpMark } from "@washingtonpost/wpds-assets";

const Wrapper = styled("a", {
  textAlign: "center",
  "@sm": {
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    position: "relative"
  }
});

const StyledDivider = styled(Divider, {
  display: "none",
  "@sm": {
    display: "flex",
    "&[data-orientation=vertical]": {
      height: "20px",
      backgroundColor: theme.colors.accessible,
      alignSelf: "center",
      marginRight: theme.space["100"]
    }
  }
});

const fadeOut = keyframes({
  "0%": { opacity: 1 },
  "100%": { opacity: 0 }
});

const fadeIn = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 }
});

const StyledWashingtonPost = styled(WashingtonPost, {
  width: "188px",
  display: "block",
  opacity: 1,
  visibility: "visible",
  animation: `${fadeIn} 0.7s cubic-bezier(0.65, 0, 0.35, 1) 0s`,
  // to keep contents from wiggling in ios mobile
  transform: "translate3d(0, 0, 0)",
  "@sm": {
    top: "-2px",
    width: "160px",
    position: "absolute",
    marginLeft: theme.space["100"]
  },
  variants: {
    hasScrolled: {
      true: {}
    },
    showLogo: {
      false: {
        "@media (min-width: 1150px)": {
          opacity: 0,
          visibility: "hidden",
          animation: `${fadeOut} 0.7s cubic-bezier(0.65, 0, 0.35, 1) 0s`,
          pointerEvents: "none"
        }
      }
    },
    showwpmark: {
      true: {
        "@sm": {
          opacity: 0,
          animation: `${fadeOut} 0.7s cubic-bezier(0.65, 0, 0.35, 1) 0s`,
          pointerEvents: "none"
        }
      },
      false: {}
    }
  },
  compoundVariants: [
    {
      hasScrolled: true,
      showwpmark: false,
      css: {
        "@sm": {
          opacity: 1,
          animation: `${fadeIn} 0.7s cubic-bezier(0.65, 0, 0.35, 1) 0s`
        }
      }
    }
  ]
});

const StyledWpMark = styled(WpMark, {
  width: theme.sizes["175"],
  height: "26px",
  position: "absolute",
  marginLeft: theme.space["100"],
  // to keep contents from wiggling in ios mobile
  transform: "translate3d(0, 0, 0)",
  "@notSm": {
    display: "none",
    visibility: "hidden"
  },
  variants: {
    showwpmark: {
      true: {
        "@sm": {
          opacity: 1,
          animation: `${fadeIn} 0.7s cubic-bezier(0.65, 0, 0.35, 1) 0s`
        }
      },
      false: {
        "@sm": {
          opacity: 0,
          animation: `${fadeOut} 0.7s cubic-bezier(0.65, 0, 0.35, 1) 0s`,
          pointerEvents: "none"
        }
      }
    }
  }
});

const Slogan = styled("span", {
  fontFamily: theme.fonts.body,
  fontSize: theme.fontSizes["075"],
  color: theme.colors.gray100,
  lineHeight: theme.lineHeights["100"],
  fontStyle: "italic",
  opacity: 1,
  display: "block",
  animation: `${fadeIn} 0.7s cubic-bezier(0.65, 0, 0.35, 1) 0s`,
  "@sm": {
    display: "none",
    opacity: 0,
    animation: `${fadeOut} 0.7s cubic-bezier(0.65, 0, 0.35, 1) 0s`
  }
});

const HeaderLogo = (props) => {
  const {
    hasScrolled,
    showLogo,
    showWpMark,
    slogan,
    url,
    variant = "secondary" // with white nav, the logo should be black now
  } = props;

  return (
    <Wrapper href={url}>
      <StyledDivider orientation="vertical" />
      <StyledWashingtonPost
        aria-label="The Washington Post homepage."
        color={variant}
        fill={variant}
        hasScrolled={hasScrolled} // should only animate after scrolling
        label="The Washington Post logo"
        showLogo={showLogo}
        showwpmark={!!showWpMark}
      />
      {hasScrolled && (
        <StyledWpMark
          color={variant}
          fill={variant}
          label="The Washington Post logo mark"
          showwpmark={!!showWpMark}
        />
      )}
      <Slogan aria-label="Tagline, Democracy dies in darkness">{slogan}</Slogan>
    </Wrapper>
  );
};

HeaderLogo.propTypes = {
  hasScrolled: PropTypes.bool,
  showLogo: PropTypes.bool,
  showWpMark: PropTypes.bool,
  slogan: PropTypes.string,
  url: PropTypes.string,
  /** Variant Choice */
  variant: PropTypes.oneOf(["primary", "outlined"])
};

export default HeaderLogo;
